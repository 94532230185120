import React, { useState, useEffect } from "react";
import { generateIterablePart } from "./loadingCards";
import { useSaveToWishList } from "./SaveWishListHook";
import DeleteSavedDeals from "./deleteSavedDeals";
import useGetWishList from "./getWishListHook";
// import './wishlistCard.css'
import NoDataFound from "./noDataFound";
import { resetSavedWishList } from "./actions";
import NoActiveDeals from "./noActiveDeals";
import { showToast } from "./Toast";
import { useDispatch } from "react-redux";
const WishListCard = ({
  Heading,
  data,
  loading,
  error,
  getDealsUrl,
  threeDotsEvent,
  addToListIdFunction,
}) => {
  console.log(data);
  const [saveItem, setSaveItem] = useState([]);
  const { savedWishListData, handleSaveToWishList } = useSaveToWishList();
  // const [data, setUpdateData] = useState(updatedData)
  const dispatch = useDispatch();
  const { wishListData, loadingWishList, handleGetToWishList, wishListError } =
    useGetWishList();
  console.log("data123", data);
  console.log("loading", loading);
  //  useEffect(() => {
  //   if(wishListData != undefined && wishListData.data != undefined &&   data != undefined && Heading !== "Saved Products" ){
  //      const updateddata = data.map(recent => {
  //       const savedMatch = wishListData.data.find(saved => saved.product_url === recent.product_url);
  //       return savedMatch ? { ...recent, ...savedMatch } : recent;
  //     });
  //     setSaveItem(updateddata.map(deal => deal.hasOwnProperty('wishlist_status') ? deal.wishlist_status : false));
  //     setUpdateData(updateddata);
  //   }
  //   }, [data, wishListData]);

  useEffect(() => {
    if (data) {
      if (Heading !== "Saved Products") {
        setSaveItem(
          data.map((deal) =>
            deal.hasOwnProperty("wishlist_status")
              ? deal.wishlist_status
              : false
          )
        );
      } else {
        setSaveItem(data.map((deal) => deal.is_favourite));
      }
    }
  }, [data]);
  const saveDeal = (deal, index) => {
    console.log("wishcard", deal);
    let obj = {
      product_title: deal.product_title,
      product_category: "",
      product_image: deal.product_image,
      product_price: deal.product_price,
      product_url: deal.product_url,
      uuid_type: "deal",
      uuid: "",
      type: "ADD",
    };
    handleSaveToWishList(obj);
    console.log("savedWishListData ", savedWishListData);
    toggleSaveItem(index);
  };
  const toggleSaveItem = (index) => {
    setSaveItem((prevSaveItem) => {
      const newSaveItem = [...prevSaveItem];
      newSaveItem[index] = !newSaveItem[index];
      return newSaveItem;
    });
  };

  const productUpdate = (item) => {
    threeDotsEvent(item);
    // setProductData(item)
  };

  // const addRemoveFromFavourite = (id, url, index) => {
  //   toggleSaveItem(index);

  //   // addToListIdFunction(id,url)
  //   // if (index === 0 || index === 3) {
  //   let updatedListIds = id;

  //   // Convert updatedListIds to an array if it's a number
  //   if (typeof updatedListIds === "number") {
  //     updatedListIds = [updatedListIds];
  //   }

  //   // Fetch IDs from localStorage if present
  //   const localStorageIdsString = localStorage.getItem("selectedLists")
  //     ? localStorage.getItem("selectedLists")
  //     : [];
  //   let localStorageIds = JSON.parse(localStorageIdsString);
  //   console.log("archanapatel", localStorageIds);
  //   // Remove updatedListIds from localStorageIds if present
  //   updatedListIds.forEach((updatedId) => {
  //     const index = localStorageIds.indexOf(updatedId);
  //     if (index !== -1) {
  //       // If id is in localStorageIds, remove it
  //       localStorageIds.splice(index, 1);
  //     } else {
  //       // If id is not in localStorageIds, add it
  //       localStorageIds.push(updatedId);
  //     }
  //   });

  //   console.log("Combined IDs:", localStorageIds);

  //   // Save combined IDs back to localStorage
  //   localStorage.setItem("selectedLists", JSON.stringify(localStorageIds));

  //   // Ensure combinedIds is an array before passing to addToListFunction
  //   addToListIdFunction(localStorageIds, url);
  //   // }
  // };

  const addRemoveFromFavourite = (list_id, url, index) => {
    // Toggle the UI state of the item
    toggleSaveItem(index);

    // Dynamically set the list of IDs to toggle (you can replace 1 with your dynamic value)
    let updatedListIds = [1]; // Replace '1' with the actual ID(s) you want to toggle

    // Ensure updatedListIds is an array
    updatedListIds = Array.isArray(updatedListIds)
      ? updatedListIds
      : [updatedListIds];

    // Split the comma-separated string into an array and filter out empty strings
    const existingListArray = list_id
      .split(",")
      .filter((id) => id.trim() !== "");

    // Convert string IDs to numbers (if IDs are numbers)
    const existingListIds = existingListArray.map((id) => parseInt(id, 10));

    // Create a Set for the current list to handle uniqueness
    const existingListSet = new Set(existingListIds);

    // Flag to track whether we need to update the list
    let shouldUpdate = false;

    // Add/remove IDs in the set
    updatedListIds.forEach((id) => {
      if (id !== null && !existingListSet.has(id)) {
        shouldUpdate = true;
        existingListSet.add(id); // Add if not in the set
      } else if (existingListSet.has(id)) {
        shouldUpdate = true;
        existingListSet.delete(id); // Remove if already in the set
      }
    });

    // Convert the Set back to an array
    const updatedIdsArray = Array.from(existingListSet);

    // Convert array back to a comma-separated string
    const updatedListIdString = updatedIdsArray.join(",");

    // Log the final combined IDs
    console.log("Combined IDs:", updatedListIdString);

    // Pass the updated list to your handler function
    if (shouldUpdate) {
      addToListIdFunction(updatedListIdString, url);
    }
  };

  const [fulfillmentStatus, setFulfillmentStatus] = useState([]);
  useEffect(() => {
    if (data) {
      setFulfillmentStatus(data.map((deal) => deal.is_fulfilled));
    }
  }, [data]);
  const toggleFulfillmentStatus = (index) => {
    setFulfillmentStatus((prevStatus) => {
      if (prevStatus != undefined)
        prevStatus.map((status, idx) => (idx === index ? !status : status));
    });
  };

  const [isDelete, setIsDelete] = useState(false);
  const [productToDelete, setProductToDelete] = useState({});
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const removeDeal = (baseUrl, deal, index) => {
    console.log("delete deal ", deal);
    // let product_img = baseUrl + deal.deal_image;
    let obj = {
      product_title: deal.product_title,
      product_category: "",
      product_image: deal.product_image,
      product_price: deal.product_price,
      product_url: deal.product_url,
      uuid_type: "deal",
      uuid: deal.uuid,
      type: "REMOVE",
    };
    let productObj = {
      index: index,
      obj: obj,
    };
    setProductToDelete(productObj);
    setIsDeletePopup(true);
  };
  useEffect(() => {
    console.log("isDelete ", isDelete);
    if (isDelete == true) {
      handleSaveToWishList(productToDelete.obj);
      setIsDelete(false);
    }

    if (savedWishListData.message) {
      toggleSaveItem(productToDelete.index);
      showToast("success", savedWishListData.message);
      dispatch(resetSavedWishList());
      handleGetToWishList();
      setIsDeletePopup(false);
    } else {
    }
  }, [isDelete, savedWishListData]);

  return (
    <>
      {loading ? (
        <div
          className="loading_section"
          style={{
            width: " fit-content",
            // margin: "5px",
            gap: "13px",
            padding: "0px 13px",
          }}
        >
          {Array.from({ length: 4 }, (_, index) => generateIterablePart(index))}
        </div>
      ) : (
        <div>
          {data && data && data.length > 0 ? (
            <div>
              <h3>{Heading}</h3>

              <div className="items_wrapper">
                <div className="another-cards-wrapper">
                  {data.map((item, index) =>
                    item.product_title !== "" ? (
                      <div
                        className="another_card"
                        style={{ width: "auto", padding: "0" }}
                        key={index}
                      >
                        <div className="wishlist_card">
                          <div
                            onClick={() => {
                              getDealsUrl(
                                item.product_url,
                                item.deal_store_id,
                                item.store_name
                              );
                            }}
                          >
                            <div
                              style={{
                                alignItems: "center",
                                display: "flex",
                                height: "213px",
                                marginBottom: "5px",
                              }}
                            >
                              <img
                                src={item.product_image}
                                alt=""
                                className="images-size"
                                style={{
                                  minWidth: "100%",
                                  borderRadius: "10px",
                                  borderRadius: "0",
                                  height: "156px",
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                            <div style={{ padding: "10px" }}>
                              <div
                                className="fullfilledDiv"
                                style={{
                                  display: fulfillmentStatus[index]
                                    ? "flex"
                                    : "none",
                                }}
                              >
                                {fulfillmentStatus[index] ? (
                                  <span className="tag fulfilled fullfilledSpan">
                                    Fulfilled
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="price">
                                <span> ₹ {item.product_price}</span>
                                {/* <span className="line">
                                  ₹{item.deal_original_price}
                                </span> */}
                                {/* <span className='line'>{item.product_price}</span> */}
                                {/* <span>{item.product_price}</span> */}
                              </div>
                              <div>
                                <p className="title">{item.product_title}</p>
                              </div>
                              <div>
                                {/* <p style={{ marginTop: '5px', fontSize: '9px', fontWeight: '600' }}>{item.deal_store}</p> */}
                              </div>
                            </div>
                            {item.deal_discount_percentage ? (
                              <span className="discount">
                                {" "}
                                <i className="fa-solid fa-tags"></i>{" "}
                                {item.deal_discount_percentage + "%"}{" "}
                              </span>
                            ) : (
                              <span></span>
                            )}
                          </div>
                          <div>
                            {Heading !== "Saved Products" ? (
                              <>
                                <span
                                  className="discount saveDeal"
                                  onClick={() => {
                                    // saveDeal(item, index);
                                    removeDeal(item.product_url, item, index);
                                  }}
                                  style={{
                                    display: saveItem[index] ? "block" : "none",
                                    marginLeft: "0px",
                                    top: "-9px",
                                  }}
                                >
                                  <i
                                    className="fa-solid fa-heart"
                                    style={{
                                      verticalAlign: "middle",
                                      minWidth: "20px",
                                      minHeight: "45PX",
                                      maxWidth: "20px",
                                      maxHeight: "45PX",
                                      color: "#9D4EDD",
                                    }}
                                  ></i>
                                </span>
                                <span
                                  className="discount saveDeal"
                                  onClick={() => {
                                    saveDeal(item, index);
                                  }}
                                  style={{
                                    display: !saveItem[index]
                                      ? "block"
                                      : "none",
                                    marginLeft: "0px",
                                    top: "-9px",
                                  }}
                                >
                                  <i
                                    className="fa-regular fa-heart"
                                    style={{
                                      verticalAlign: "middle",
                                      minWidth: "20px",
                                      minHeight: "45PX",
                                      maxWidth: "20px",
                                      maxHeight: "45PX",
                                      color: "#9D4EDD",
                                    }}
                                  ></i>
                                </span>
                              </>
                            ) : (
                              <>
                                <span
                                  className="discount saveDeal"
                                  onClick={() => {
                                    addRemoveFromFavourite(
                                      item.list_id,
                                      item.product_url,
                                      index
                                    );
                                  }}
                                  style={{
                                    display: saveItem[index] ? "block" : "none",
                                    marginLeft: "-1px",
                                    top: "-3",
                                  }}
                                >
                                  <i
                                    className="fa-solid fa-star"
                                    style={{
                                      verticalAlign: "middle",
                                      minWidth: "20px",
                                      minHeight: "45PX",
                                      maxWidth: "20px",
                                      maxHeight: "45PX",
                                      color: "#9D4EDD",
                                    }}
                                  ></i>
                                </span>
                                <span
                                  className="discount saveDeal"
                                  onClick={() => {
                                    addRemoveFromFavourite(
                                      item.list_id,
                                      item.product_url,
                                      index
                                    );
                                  }}
                                  style={{
                                    display: !saveItem[index]
                                      ? "block"
                                      : "none",
                                    marginLeft: "-1px",
                                    top: "-3px",
                                  }}
                                >
                                  <i
                                    className="fa-regular fa-star"
                                    style={{
                                      verticalAlign: "middle",
                                      minWidth: "20px",
                                      minHeight: "45PX",
                                      maxWidth: "20px",
                                      maxHeight: "45PX",
                                      color: "#9D4EDD",
                                    }}
                                  ></i>
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                        {Heading == "Saved Products" ? (
                          <div
                            onClick={() => {
                              productUpdate(item);
                            }}
                          >
                            <i
                              className="fa fa-ellipsis-v "
                              aria-hidden="true"
                              style={{
                                position: "absolute",
                                left: "88%",
                                top: "95%",
                                transform: "rotate(90deg)",
                                fontSize: "8px",
                                width: "3%",
                                height: "4%",
                              }}
                            ></i>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      ""
                    )
                  )}{" "}
                </div>
              </div>
              {isDeletePopup == true ? (
                <DeleteSavedDeals
                  setIsDelete={setIsDelete}
                  setIsDeletePopup={setIsDeletePopup}
                  message={"product"}
                />
              ) : (
                ""
              )}
            </div>
          ) : (
            <div style={{ margin: "0px 10px" }}>
              <NoDataFound title="No data found" storeName="amazon" />
            </div>
          )}
        </div>
      )}
      {error && <p> </p>}
    </>
  );
};

export default WishListCard;
