export const buildUrl = (
  uuid,
  url,
  platform,
  store_id,
  event = "plugin_close",
  source = "PLUGIN"
) => {
  console.log(store_id, uuid, url, platform);

  const baseUrl = "https://api.deals2buy.in/openUrl.php";
  const params = new URLSearchParams({ uuid, url, source, event, store_id });
  return `${baseUrl}?${params.toString()}`;
};

export function getStoreName(url) {
  let storeUrl = !url ? "" : url;
  console.log("storeUrl ", storeUrl);
  // Use a regex to extract the domain name without 'www' and '.com'
  const regex = /https?:\/\/(?:www\.)?([^\.]+)\.(com|in)/;
  const match = storeUrl.match(regex);

  // If there's a match, return the store name
  if (match && match[1]) {
    return match[1];
  } else {
    return null;
  }
}

export const getDealsUrl = (url, storeId, domain) => {
  console.log("domain ", domain);
  handleRedirect(url, storeId, true, domain);
};

export const handleRedirect = (url, storeId, redirectTab, domain) => {
  let HTMLencodedUrl = encodeURIComponent(url);
  if (storeId != "") {
    HTMLencodedUrl = `${HTMLencodedUrl}&${storeId}`;
  }
  let finalUrl = `https://api.deals2buy.in/redirect.php?url=${HTMLencodedUrl}&store_id=${storeId}`;
  console.log("dealUrl ", finalUrl);
  // const link = document.createElement('a');
  // link.href = finalUrl; // Replace with your desired URL
  // link.target = '_blank'; // Open in the same tab; use '_blank' to open in a new tab
  // link.click();

  const parentWindow = window.parent;
  console.log(domain);

  parentWindow.postMessage(
    { action: "redirectToStore", storeUrl: finalUrl, redirectTab: redirectTab },
    domain
  );
};

// Helper function to get the cookie value
export const getCookieValue = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
  return null;
};

// Helper function to calculate the difference in days
// export const timeAgo = (date) => {
//   const now = new Date();
//   const past = new Date(date);
//   const diff = Math.abs(now - past); // Difference in milliseconds

//   const seconds = Math.floor(diff / 1000);
//   const minutes = Math.floor(seconds / 60);
//   const hours = Math.floor(minutes / 60);
//   const days = Math.floor(hours / 24);

//   if (days > 0) {
//     return days === 1 ? "1 day ago" : `${days} days ago`;
//   } else if (hours > 0) {
//     return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
//   } else if (minutes > 0) {
//     return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
//   } else {
//     return seconds === 1 ? "1 second ago" : `${seconds} seconds ago`;
//   }
// };

export const timeAgo = (date_deal) => {
  // Parse date string in 'YYYY-MM-DD HH:mm:ss' format
  const parseDate = (dateString) => {
    const [datePart, timePart] = dateString.split(" ");
    const [year, month, day] = datePart.split("-").map(Number);
    const [hours, minutes, seconds] = timePart.split(":").map(Number);

    // Create a new Date object (months are 0-indexed in JavaScript)
    return new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));
  };

  // Convert to Date object if it's a valid date string
  const date = typeof date_deal === "string" ? parseDate(date_deal) : date_deal;

  // Ensure that `date` is a valid Date object
  if (isNaN(date.getTime())) {
    throw new Error("Invalid date");
  }

  const today = new Date();

  // Calculate the difference in seconds
  let delta = Math.abs(today - date) / 1000;

  // Calculate years, days, hours, minutes, and seconds
  const days = Math.floor(delta / 86400);
  delta -= days * 86400;

  const hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  const minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;

  const seconds = Math.floor(delta);

  // Handle years
  const yearDiff = today.getFullYear() - date.getFullYear();
  if (yearDiff > 0) {
    return (
      yearDiff === 1 ? `${yearDiff} year ago` : `${yearDiff} years ago`
    ).toLowerCase();
  }

  // Handle days
  if (days > 0) {
    return `${days} ${days === 1 ? "day ago" : "days ago"}`.toLowerCase();
  }

  // Handle hours
  if (hours > 0) {
    return `${hours} ${hours === 1 ? "hour ago" : "hours ago"}`.toLowerCase();
  }

  // Handle minutes
  if (minutes > 0) {
    return `${minutes} minutes ago`.toLowerCase();
  }

  // Handle seconds
  if (seconds > 0) {
    return "just now".toLowerCase();
  }

  return "now".toLowerCase(); // Default case
};
