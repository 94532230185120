import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

const SingleProductDisplay = ({ singleProduct }) => {
  const [loading, setLoading] = useState(true);
  const [titleLoading, setTitleLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [priceLoading, setPriceLoading] = useState(false); // Add state for price loading

  const title = singleProduct?.product_title;
  const wordsArray = title ? title.split(" ") : [];
  const firstWord = wordsArray[0] || "";
  console.log("SingleProductDisplay", singleProduct);
  useEffect(() => {
    if (!singleProduct) {
      setLoading(true); // Show infinite loading if no data after 2 seconds
    } else {
      setLoading(false);
      if (
        (singleProduct.imgSrc || singleProduct.product_image) &&
        !singleProduct.product_title
      ) {
        setTitleLoading(true);
        setTimeout(() => {
          setTitleLoading(false);
        }, 3000); // Simulate title loading for 3 seconds
      }

      if (
        (!singleProduct.imgSrc || !singleProduct.product_image) &&
        singleProduct.product_title
      ) {
        setImageLoading(true);
        setTimeout(() => {
          setImageLoading(false);
        }, 3000); // Simulate image loading for 6 seconds
      }

      if (!singleProduct.price && !singleProduct.product_price) {
        setPriceLoading(true);
        setTimeout(() => {
          setPriceLoading(false);
        }, 3000); // Simulate price loading for 3 seconds
      }
    }
  }, [singleProduct]);

  return (
    <ul className="singleProductCard">
      <li style={{ background: "transparent", border: "none", padding: "0px" }}>
        {imageLoading ? (
          <Skeleton height={130} width={115} />
        ) : singleProduct.imgSrc || singleProduct.product_image ? (
          <img
            src={singleProduct.imgSrc || singleProduct.product_image}
            alt={singleProduct.product_title}
          />
        ) : (
          <div
            style={{
              height: "130px",
              borderRadius: "10px",
              minWidth: "115px",
              background: "white",
            }}
          ></div>
        )}

        <div
          // className="productDetail"
          style={{
            padding: "0px 10px",
          }}
        >
          <div>
            <p
              className="title"
              style={{
                textTransform: "uppercase",
                fontWeight: "600",
              }}
            >
              {firstWord}
            </p>
          </div>

          {titleLoading ? (
            <Skeleton height={10} width={150} />
          ) : (
            <div>
              <p
                className="title"
                // style={{
                //   whiteSpace: "normal",
                //   fontSize: "12px",
                //   fontWeight: "400",
                //   WebkitLineClamp: "2",
                // }}
              >
                {singleProduct.product_title}
              </p>
            </div>
          )}

          <div
            className="price"
            // style={{
            //   lineHeight: "28px",
            //   marginTop: "10px",
            //   fontSize: "15px",
            //   fontWeight: "600",
            //   color: "black",
            // }}
          >
            {priceLoading ? (
              <Skeleton height={20} width={80} />
            ) : (
              <>
                {/* <span> ₹ {item.deal_price}</span>
                {" "}
                <span className="line">
                   ₹{item.deal_original_price}
                  {" "}
                </span> */}
                <span>
                  ₹ {singleProduct.price || singleProduct.product_price}
                </span>
                {/* <span>
                  ₹{" "}
                  {singleProduct.deal_original_price ||
                    singleProduct.deal_original_price}
                </span> */}
              </>
            )}
          </div>
        </div>
      </li>
    </ul>
  );
};

export default SingleProductDisplay;
