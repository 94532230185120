import "./Shopping.css";
import "./CardItem";
import {
  HotDeals,
  SimilarDeals,
  TrendingDeals,
  RecentlyViewdDeals,
  AboutStore,
  ShopAt,
  ShippingInfo,
  ProductDetails,
} from "./CardItem";
import SingleProductDisplay from "./singleProductComponent";

const Yourshopping = ({
  serverPageType,
  titlePayload,
  domain,
  activeProductData,
}) => {
  console.log("domain123", domain);
  return (
    <div className="shopping_wrapper">
      {serverPageType == "ProductDetail" && (
        <ProductDetails domain={domain} activeProductData={activeProductData} />
      )}
      {/* <section id="shipping_section"> */}
      <ShippingInfo domain={domain} />
      {/* </section> */}

      <SimilarDeals domain={domain} />

      {/* <section className="deals_section"> */}
      <HotDeals domain={domain} />
      {/* </section> */}
      {/* <section className="trending_section"> */}
      <TrendingDeals domain={domain} />
      {/* </section> */}

      {/* <section > */}
      <ShopAt domain={domain} />
      {/* </section> */}
    </div>
  );
};

export default Yourshopping;
