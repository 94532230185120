import "./Shopping.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProductList,
  fetchSimilarProduct,
  resetSavedWishList,
} from "./actions";
import watchImg from "../assets/img2.jpg";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { buildUrl, getStoreName, handleRedirect } from "./BuildUrl";
import useUniqueId from "./useUniqueIdHook";
import { useSaveToWishList } from "./SaveWishListHook";
import { generateIterablePart } from "./loadingCards";
import DeleteSavedDeals from "./deleteSavedDeals";
import Slider from "./reusableSlider";
import hot_fire from "../hot_fire.png";
import { getDaysLeft } from "./CarItemHandlingComponent";
import SingleProductDisplay from "./singleProductComponent";
import { showToast } from "./Toast";

const HotDeals = ({ props, domain }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.productList);
  const loading = useSelector((state) => state.loadingProductList);
  const error = useSelector((state) => state.errorProductList);
  console.log("loading", loading);
  const { uniqueIdData } = useUniqueId();
  const [uuid, setUuid] = useState("");
  // const platform =getStoreName(domain)

  useEffect(() => {
    if (uniqueIdData && uniqueIdData.user_id) {
      console.log("uniqueIdData ", uniqueIdData.user_id);
      setUuid(uniqueIdData.user_id);
    }
  }, [uniqueIdData]);

  const { savedeals } = useSaveToWishList();

  const { savedWishListData, handleSaveToWishList } = useSaveToWishList();
  // const platform =getStoreName(domain)

  useEffect(() => {
    console.log("savedWishListData", savedWishListData);
    if (
      savedWishListData &&
      savedWishListData.status &&
      savedWishListData.message === "Added to wishlist"
    ) {
      showToast("success", savedWishListData.message);
      dispatch(resetSavedWishList());
    }
  }, [savedWishListData]);

  useEffect(() => {
    if (uniqueIdData && uniqueIdData.user_id) {
      console.log("uniqueIdData ", uniqueIdData.user_id);
      setUuid(uniqueIdData.user_id);
    }
  }, [uniqueIdData]);
  const saveDeal = (baseUrl, deal, index) => {
    let product_img = baseUrl + deal.deal_image;
    let obj = {
      product_title: deal.deal_title,
      product_category: "",
      product_image: product_img,
      product_price: deal.deal_price,
      product_url: deal.deal_url,
      uuid_type: "deal",
      uuid: deal.deal_uuid,
      type: "ADD",
    };
    handleSaveToWishList(obj);
    console.log("savedWishListData ", savedWishListData);
    toggleSaveItem(index);
  };
  const toggleSaveItem = (index) => {
    setSaveItem((prevSaveItem) => {
      const newSaveItem = [...prevSaveItem];
      newSaveItem[index] = !newSaveItem[index];
      return newSaveItem;
    });
  };
  const getDealsUrl = (url, platform, storeId) => {
    // const dealUrl = buildUrl(uuid, url, platform,storeId);
    //  console.log('dealUrl ',dealUrl)
    handleRedirect(url, storeId, true, domain);
  };

  useEffect(() => {
    dispatch(fetchProductList({ store_url: domain }));
  }, [dispatch]);

  // toggleheart
  const [saveItem, setSaveItem] = useState([]);
  useEffect(() => {
    if (data.trending_deals) {
      setSaveItem(data.hot_deals.map((deal) => deal.is_wishlisted));
    }
  }, [data]);

  const [daysLeft, setDaysLeft] = useState([]);
  useEffect(() => {
    if (data.trending_deals) {
      setDaysLeft(
        data.hot_deals.map((deal) => getDaysLeft(deal.deal_end_date))
      );
    }
  }, [data]);

  const [isDelete, setIsDelete] = useState(false);
  const [productToDelete, setProductToDelete] = useState({});
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const removeDeal = (baseUrl, deal, index) => {
    let product_img = baseUrl + deal.deal_image;
    let obj = {
      product_title: deal.deal_title,
      product_category: "",
      product_image: product_img,
      product_price: deal.deal_price,
      product_url: deal.deal_url,
      uuid_type: "deal",
      uuid: deal.deal_uuid,
      type: "REMOVE",
    };
    let productObj = {
      index: index,
      obj: obj,
    };
    setProductToDelete(productObj);
    setIsDeletePopup(true);
  };
  useEffect(() => {
    console.log("isDelete ", isDelete);
    if (isDelete == true) {
      handleSaveToWishList(productToDelete.obj);
      setIsDelete(false);
    }

    if (savedWishListData.message == "Removed from wishlist") {
      toggleSaveItem(productToDelete.index);
      setIsDeletePopup(false);
    } else {
    }
  }, [isDelete, savedWishListData]);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: " repeat(2, 1fr)",
            gap: "1px",
            width: " fit-content",
            // margin: "5px",
            gap: "13px",
            padding: "0px 13px",
          }}
        >
          {Array.from({ length: 4 }, (_, index) => generateIterablePart(index))}
        </div>
      ) : (
        <>
          <>
            {data && data.hot_deals && data.hot_deals.length > 0 && (
              <section className="deals_section">
                <h3>Hot Deals</h3>
                <div className="items_wrapper">
                  <div className="another-cards-wrapper">
                    {data.hot_deals.map((item, index) => (
                      <div className="another_card">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "20px",
                          }}
                          key={index}
                          onClick={() => {
                            getDealsUrl(
                              item.deal_url,
                              item.deal_store,
                              item.deal_store_id,
                              item.store_name
                            );
                          }}
                        >
                          <div
                            style={{
                              alignItems: "center",
                              display: "flex",
                              height: "169px",
                              // marginBottom: '35px'
                            }}
                          >
                            <img
                              src={data.deal_img_base_url + item.deal_image}
                              alt=""
                              className="images-size"
                              style={{ minWidth: "100%", borderRadius: "10px" }}
                            />
                          </div>
                          <div className="price">
                            <span> ₹ {item.deal_price}</span>
                            <span className="line">
                              ₹{item.deal_original_price}
                            </span>
                          </div>
                          <div>
                            <p className="title">{item.deal_title}</p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            <p
                              style={{
                                marginTop: "5px",
                                fontSize: "9px",
                                fontWeight: "600",
                              }}
                            >
                              {item.deal_store}
                            </p>
                            <span style={{ marginTop: "-4px" }}>
                              <small
                                style={{
                                  fontSize: "9px",
                                  fontWeight: "600",
                                }}
                              >
                                {daysLeft[index]} Day Left
                              </small>
                              <img
                                src={hot_fire}
                                alt=""
                                srcset=""
                                style={{
                                  verticalAlign: "middle",
                                  minWidth: "16px",
                                  minHeight: "22px",
                                  maxWidth: "21px",
                                  maxHeight: "23px",
                                }}
                              />
                            </span>
                          </div>
                          {item.deal_discount_percentage ? (
                            <span className="discount">
                              {" "}
                              <i className="fa-solid fa-tags"></i>{" "}
                              {item.deal_discount_percentage + "%"}{" "}
                            </span>
                          ) : (
                            <span></span>
                          )}
                        </div>
                        <span
                          className="discount saveDeal"
                          onClick={() => {
                            removeDeal(data.deal_img_base_url, item, index);
                          }}
                          style={{
                            display: saveItem[index] ? "block" : "none",
                          }}
                        >
                          <i
                            className="fa-solid fa-heart"
                            style={{
                              verticalAlign: "middle",
                              minWidth: "20px",
                              minHeight: "45PX",
                              maxWidth: "20px",
                              maxHeight: "45PX",
                              color: "#9D4EDD",
                            }}
                          ></i>
                        </span>
                        <span
                          className="discount saveDeal"
                          onClick={() => {
                            saveDeal(
                              data.deal_img_base_url,
                              item,
                              index,
                              "add"
                            );
                          }}
                          style={{
                            display: !saveItem[index] ? "block" : "none",
                          }}
                        >
                          <i
                            className="fa-regular fa-heart"
                            style={{
                              verticalAlign: "middle",
                              minWidth: "20px",
                              minHeight: "45PX",
                              maxWidth: "20px",
                              maxHeight: "45PX",
                              color: "#9D4EDD",
                            }}
                          ></i>
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            )}
          </>
          <>
            {/* <DeleteSavedDeals setIsDelete={setIsDelete}    /> */}
            {isDeletePopup == true ? (
              <DeleteSavedDeals
                setIsDelete={setIsDelete}
                setIsDeletePopup={setIsDeletePopup}
                message={"product"}
              />
            ) : (
              ""
            )}
          </>
        </>
      )}
      {error && <p> </p>}
    </>
  );
};

// const SimilarDeals = ({ titlePayload, domain }) => {
//   const dispatch = useDispatch();
//   const similarProduct = useSelector((state) => state.similarProduct);
//   const loadingforSimilarProduct = useSelector(
//     (state) => state.loadingSimilarProduct
//   );
//   const errorforSimilarProduct = useSelector(
//     (state) => state.errorSimilarProduct
//   );

//   const { uniqueIdData } = useUniqueId();
//   const [uuid, setUuid] = useState("");
//   // const platform =getStoreName(domain)

//   useEffect(() => {
//     if (uniqueIdData && uniqueIdData.user_id) {
//       console.log("uniqueIdData ", uniqueIdData.user_id);
//       setUuid(uniqueIdData.user_id);
//     }
//   }, [uniqueIdData]);

//   const getDealsUrl = (url, platform, storeId) => {
//     // const dealUrl = buildUrl(uuid, url, platform,storeId);
//     //  console.log('dealUrl ',dealUrl)
//     handleRedirect(url, storeId, true, domain);
//   };

//   useEffect(() => {
//     if (titlePayload) {
//       dispatch(fetchSimilarProduct(titlePayload));
//     }
//   }, [dispatch]);

//   console.log(similarProduct);
//   console.log(loadingforSimilarProduct);
//   console.log(errorforSimilarProduct);

//   return (
//     <>
//       {loadingforSimilarProduct ? (
//         <div style={{ margin: "5px" }}>
//           {Array.from({ length: 4 }, (_, index) => (
//             <div className="items_wrapper" key={index}>
//               <div className="cards-wrapper">
//                 <div className="card" style={{ width: "100%" }}>
//                   <Skeleton
//                     height={90}
//                     width={90}
//                     src={watchImg}
//                     alt=""
//                     className="images_size"
//                   />
//                   <div>
//                     <Skeleton
//                       height={10}
//                       width={150}
//                       style={{ marginTop: "40px" }}
//                     />
//                     <Skeleton height={10} width={150} />
//                   </div>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       ) : (
//         <div>
//           {similarProduct &&
//           similarProduct.similar_deals &&
//           similarProduct.similar_deals.length > 0 ? (
//             <div
//             // style={{ marginBottom: "20px" }}
//             >
//               <h3>Similar Products</h3>
//               <div className="items_wrapper">
//                 <div className="cards-wrapper">
//                   {similarProduct.similar_deals.map((item, index) => (
//                     <div
//                       className="card"
//                       key={index}
//                       onClick={() => {
//                         getDealsUrl(
//                           item.deal_url,
//                           item.deal_store,
//                           item.deal_store_id,
//                           item.store_name
//                         );
//                       }}
//                     >
//                       <a
//                         href={item.product_url}
//                         target="blank"
//                         className="images_size"
//                         style={{ minWidth: "100%", borderRadius: "10px" }}
//                       >
//                         <img src={watchImg} alt="" className="images_size" />
//                       </a>
//                       <p style={{ fontSize: "12px" }}>{item.product_name}</p>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           ) : (
//             ""
//           )}
//         </div>
//       )}
//       {errorforSimilarProduct && <p> </p>}
//     </>
//   );
// };

const SimilarDeals = ({ titlePayload, domain }) => {
  const dispatch = useDispatch();
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [productToDelete, setProductToDelete] = useState({});
  const { savedWishListData, handleSaveToWishList } = useSaveToWishList();

  const data = useSelector((state) => state.productList);
  const loading = useSelector((state) => state.loadingProductList);
  const error = useSelector((state) => state.errorProductList);

  const { uniqueIdData } = useUniqueId();
  const [uuid, setUuid] = useState("");
  // const platform =getStoreName(domain)

  useEffect(() => {
    if (uniqueIdData && uniqueIdData.user_id) {
      console.log("uniqueIdData ", uniqueIdData.user_id);
      setUuid(uniqueIdData.user_id);
    }
  }, [uniqueIdData]);

  const getDealsUrl = (url, platform, storeId) => {
    // const dealUrl = buildUrl(uuid, url, platform,storeId);
    //  console.log('dealUrl ',dealUrl)
    handleRedirect(url, storeId, true, domain);
  };

  useEffect(() => {
    if (titlePayload) {
      dispatch(fetchSimilarProduct(titlePayload));
    }
  }, [dispatch]);

  const [saveItem, setSaveItem] = useState([]);
  useEffect(() => {
    if (data.similar_deals) {
      setSaveItem(data.similar_deals.map((deal) => deal.is_wishlisted));
    }
  }, [data]);

  const removeDeal = (baseUrl, deal, index) => {
    let product_img = baseUrl + deal.deal_image;
    let obj = {
      product_title: deal.deal_title,
      product_category: "",
      product_image: product_img,
      product_price: deal.deal_price,
      product_url: deal.deal_url,
      uuid_type: "deal",
      uuid: deal.deal_uuid,
      type: "REMOVE",
    };
    let productObj = {
      index: index,
      obj: obj,
    };
    setProductToDelete(productObj);
    setIsDeletePopup(true);
  };

  const saveDeal = (baseUrl, deal, index) => {
    let product_img = baseUrl + deal.deal_image;
    let obj = {
      product_title: deal.deal_title,
      product_category: "",
      product_image: product_img,
      product_price: deal.deal_price,
      product_url: deal.deal_url,
      uuid_type: "deal",
      uuid: deal.deal_uuid,
      type: "ADD",
    };
    handleSaveToWishList(obj);
    console.log("savedWishListData ", savedWishListData);
    toggleSaveItem(index);
  };

  const toggleSaveItem = (index) => {
    setSaveItem((prevSaveItem) => {
      const newSaveItem = [...prevSaveItem];
      newSaveItem[index] = !newSaveItem[index];
      return newSaveItem;
    });
  };

  const [daysLeft, setDaysLeft] = useState([]);
  useEffect(() => {
    if (data.trending_deals) {
      setDaysLeft(
        data.hot_deals.map((deal) => getDaysLeft(deal.deal_end_date))
      );
    }
  }, [data]);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: " repeat(2, 1fr)",
            gap: "1px",
            width: " fit-content",
            // margin: "5px",
            gap: "13px",
            padding: "0px 13px",
          }}
        >
          {Array.from({ length: 4 }, (_, index) => generateIterablePart(index))}
        </div>
      ) : (
        <div>
          {data && data.similar_deals && data.similar_deals.length > 0 && (
            <section className="deals_section">
              <h3>Similar Products</h3>
              <div className="items_wrapper">
                <div className="another-cards-wrapper">
                  {data.similar_deals.map((item, index) => (
                    <div className="another_card">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "20px",
                        }}
                        key={index}
                        onClick={() => {
                          getDealsUrl(
                            item.deal_url,
                            item.deal_store,
                            item.deal_store_id,
                            item.store_name
                          );
                        }}
                      >
                        <div
                          style={{
                            alignItems: "center",
                            display: "flex",
                            height: "169px",
                            // marginBottom: '35px'
                          }}
                        >
                          <img
                            src={data.deal_img_base_url + item.deal_image}
                            alt=""
                            className="images-size"
                            style={{ minWidth: "100%", borderRadius: "10px" }}
                          />
                        </div>
                        <div className="price">
                          <span> ₹ {item.deal_price}</span>
                          <span className="line">
                            ₹{item.deal_original_price}
                          </span>
                        </div>
                        <div>
                          <p className="title">{item.deal_title}</p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            style={{
                              marginTop: "5px",
                              fontSize: "9px",
                              fontWeight: "600",
                            }}
                          >
                            {item.deal_store}
                          </p>
                          <span style={{ marginTop: "-4px" }}>
                            <small
                              style={{
                                fontSize: "9px",
                                fontWeight: "600",
                              }}
                            >
                              {daysLeft[index]} Day Left
                            </small>
                            <img
                              src={hot_fire}
                              alt=""
                              srcset=""
                              style={{
                                verticalAlign: "middle",
                                minWidth: "16px",
                                minHeight: "22px",
                                maxWidth: "21px",
                                maxHeight: "23px",
                              }}
                            />
                          </span>
                        </div>
                        {item.deal_discount_percentage ? (
                          <span className="discount">
                            {" "}
                            <i className="fa-solid fa-tags"></i>{" "}
                            {item.deal_discount_percentage + "%"}{" "}
                          </span>
                        ) : (
                          <span></span>
                        )}
                      </div>
                      <span
                        className="discount saveDeal"
                        onClick={() => {
                          removeDeal(data.deal_img_base_url, item, index);
                        }}
                        style={{
                          display: saveItem[index] ? "block" : "none",
                        }}
                      >
                        <i
                          className="fa-solid fa-heart"
                          style={{
                            verticalAlign: "middle",
                            minWidth: "20px",
                            minHeight: "45PX",
                            maxWidth: "20px",
                            maxHeight: "45PX",
                            color: "#9D4EDD",
                          }}
                        ></i>
                      </span>
                      <span
                        className="discount saveDeal"
                        onClick={() => {
                          saveDeal(data.deal_img_base_url, item, index, "add");
                        }}
                        style={{
                          display: !saveItem[index] ? "block" : "none",
                        }}
                      >
                        <i
                          className="fa-regular fa-heart"
                          style={{
                            verticalAlign: "middle",
                            minWidth: "20px",
                            minHeight: "45PX",
                            maxWidth: "20px",
                            maxHeight: "45PX",
                            color: "#9D4EDD",
                          }}
                        ></i>
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          )}
        </div>
      )}
      {error && <p> </p>}
    </>
  );
};

const RecentlyViewdDeals = ({ titlePayload }) => {
  const dispatch = useDispatch();
  const similarProduct = useSelector((state) => state.similarProduct);
  const loadingforSimilarProduct = useSelector(
    (state) => state.loadingSimilarProduct
  );
  console.log("loadingforSimilarProduct", loadingforSimilarProduct);
  const errorforSimilarProduct = useSelector(
    (state) => state.errorSimilarProduct
  );

  // useEffect(() => {

  //   dispatch(fetchSimilarProduct(titlePayload))

  // }, [dispatch]);

  console.log(similarProduct);
  console.log(loadingforSimilarProduct);
  console.log(errorforSimilarProduct);

  return (
    <>
      {loadingforSimilarProduct ? (
        <div>
          {Array.from({ length: 4 }, (_, index) => (
            <div className="items_wrapper" key={index}>
              <div className="cards-wrapper">
                <div className="card" style={{ width: "100%" }}>
                  <Skeleton
                    height={90}
                    width={90}
                    src={watchImg}
                    alt=""
                    className="images_size"
                  />
                  <div>
                    <Skeleton
                      height={10}
                      width={150}
                      style={{ marginTop: "40px" }}
                    />
                    <Skeleton height={10} width={150} />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div>
          {similarProduct &&
          similarProduct.similar_deals &&
          similarProduct.similar_deals.length > 0 ? (
            <div>
              <h3>Your recently viewed items</h3>
              <div className="items_wrapper">
                <div className="cards-wrapper">
                  {similarProduct.similar_deals.map((item, index) => (
                    <div className="card" key={index}>
                      <a
                        href={item.product_url}
                        target="blank"
                        className="images_size"
                      >
                        <img
                          src={watchImg}
                          alt=""
                          className="images_size"
                          style={{ minWidth: "100%", borderRadius: "10px" }}
                        />
                      </a>
                      <p style={{ fontSize: "12px" }}>{item.product_name}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
      {errorforSimilarProduct && <p> </p>}
    </>
  );
};
const TrendingDeals = ({ domain }) => {
  const data = useSelector((state) => state.productList);
  const loading = useSelector((state) => state.loadingProductList);
  const error = useSelector((state) => state.errorProductList);
  const dispatch = useDispatch();
  const { uniqueIdData } = useUniqueId();
  const { savedeals } = useSaveToWishList();
  const [uuid, setUuid] = useState("");
  const [toggleHeart, settoggleHeart] = useState([]);

  const { savedWishListData, handleSaveToWishList } = useSaveToWishList();
  // toggleheart
  useEffect(() => {
    if (
      data != undefined &&
      data.trending_deals != undefined &&
      data.trending_deals.length > 0
    ) {
      settoggleHeart(data.trending_deals.map((deal) => deal.is_wishlisted));
    }
  }, [data]);
  const toggletoggleHeart = (index) => {
    settoggleHeart((prevtoggleHeart) => {
      const newtoggleHeart = [...prevtoggleHeart];
      newtoggleHeart[index] = !newtoggleHeart[index];
      return newtoggleHeart;
    });
  };
  console.log("toggleHeart", toggleHeart);

  useEffect(() => {
    if (uniqueIdData && uniqueIdData.user_id) {
      console.log("uniqueIdData ", uniqueIdData.user_id);
      setUuid(uniqueIdData.user_id);
    }
  }, [uniqueIdData]);
  const saveDeal = (baseUrl, deal, index) => {
    let product_img = baseUrl + deal.deal_image;
    let obj = {
      product_title: deal.deal_title,
      product_category: "",
      product_image: product_img,
      product_price: deal.deal_price,
      product_url: deal.deal_url,
      uuid_type: "deal",
      uuid: deal.deal_uuid,
      type: "ADD",
    };
    handleSaveToWishList(obj);
    console.log("savedWishListData ", savedWishListData);
    toggletoggleHeart(index);
  };

  const getDealsUrl = (url, platform, storeId) => {
    // const dealUrl = buildUrl(uuid, url, platform,storeId);
    //  console.log('dealUrl ',dealUrl)
    handleRedirect(url, storeId, true, domain);
  };

  const [isDelete, setIsDelete] = useState(false);
  const [productToDelete, setProductToDelete] = useState({});
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const removeDeal = (baseUrl, deal, index) => {
    let product_img = baseUrl + deal.deal_image;
    let obj = {
      product_title: deal.deal_title,
      product_category: "",
      product_image: product_img,
      product_price: deal.deal_price,
      product_url: deal.deal_url,
      uuid_type: "deal",
      uuid: deal.deal_uuid,
      type: "REMOVE",
    };
    let productObj = {
      index: index,
      obj: obj,
    };
    setProductToDelete(productObj);
    setIsDeletePopup(true);
  };
  useEffect(() => {
    console.log("isDelete ", isDelete);
    if (isDelete == true) {
      handleSaveToWishList(productToDelete.obj);
      setIsDelete(false);
    }

    if (savedWishListData.message == "Removed from wishlist") {
      toggletoggleHeart(productToDelete.index);
      setIsDeletePopup(false);
    } else {
    }
  }, [isDelete, savedWishListData]);

  const [daysLeft, setDaysLeft] = useState([]);
  useEffect(() => {
    if (data.trending_deals) {
      setDaysLeft(
        data.hot_deals.map((deal) => getDaysLeft(deal.deal_end_date))
      );
    }
  }, [data]);

  return (
    <>
      {loading ? (
        <div
          className="loading_section"
          style={{
            width: " fit-content",
            // margin: "5px",
            gap: "13px",
            padding: "0px 13px",
          }}
        >
          {Array.from({ length: 4 }, (_, index) => generateIterablePart(index))}
        </div>
      ) : (
        <>
          {data && data.trending_deals && data.trending_deals.length > 0 && (
            <section className="deals_section">
              <h3>Trending Products</h3>
              <div className="items_wrapper">
                <div className="another-cards-wrapper">
                  {data.trending_deals.map((item, index) => (
                    <div className="another_card" key={index}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "20px",
                        }}
                        key={index}
                        onClick={() => {
                          getDealsUrl(
                            item.deal_url,
                            item.deal_store,
                            item.deal_store_id,
                            item.store_name
                          );
                        }}
                      >
                        <div
                          style={{
                            alignItems: "center",
                            display: "flex",
                            height: "169px",
                            // marginBottom: '35px'
                          }}
                        >
                          <img
                            src={data.deal_img_base_url + item.deal_image}
                            alt=""
                            className="images-size"
                            style={{ minWidth: "100%", borderRadius: "10px" }}
                          />
                        </div>
                        <div className="price">
                          <span> ₹ {item.deal_price}</span>
                          <span className="line">
                            ₹{item.deal_original_price}
                          </span>
                        </div>

                        <div>
                          <p className="title">{item.deal_title}</p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            style={{
                              marginTop: "5px",
                              fontSize: "9px",
                              fontWeight: "600",
                            }}
                          >
                            {item.deal_store}
                          </p>
                          <span style={{ marginTop: "-4px" }}>
                            <small
                              style={{
                                fontSize: "9px",
                                fontWeight: "600",
                              }}
                            >
                              {daysLeft[index]} Day Left
                            </small>
                            <img
                              src={hot_fire}
                              alt=""
                              srcset=""
                              style={{
                                verticalAlign: "middle",
                                minWidth: "16px",
                                minHeight: "22px",
                                maxWidth: "21px",
                                maxHeight: "23px",
                              }}
                            />
                          </span>
                        </div>
                        {item.deal_discount_percentage ? (
                          <span className="discount">
                            {" "}
                            <i className="fa-solid fa-tags"></i>{" "}
                            {item.deal_discount_percentage + "%"}{" "}
                          </span>
                        ) : (
                          <span></span>
                        )}
                      </div>
                      <span
                        className="discount saveDeal"
                        onClick={() => {
                          removeDeal(data.deal_img_base_url, item, index);
                        }}
                        style={{
                          display: toggleHeart[index] ? "block" : "none",
                        }}
                      >
                        <i
                          className="fa-solid fa-heart"
                          style={{
                            verticalAlign: "middle",
                            minWidth: "20px",
                            minHeight: "45PX",
                            maxWidth: "20px",
                            maxHeight: "45PX",
                            color: "#9D4EDD",
                          }}
                        ></i>
                      </span>
                      <span
                        className="discount saveDeal"
                        onClick={() => {
                          saveDeal(data.deal_img_base_url, item, index);
                        }}
                        style={{
                          display: !toggleHeart[index] ? "block" : "none",
                        }}
                      >
                        <i
                          className="fa-regular fa-heart"
                          style={{
                            verticalAlign: "middle",
                            minWidth: "20px",
                            minHeight: "45PX",
                            maxWidth: "20px",
                            maxHeight: "45PX",
                            color: "#9D4EDD",
                          }}
                        ></i>
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <>
                {/* <DeleteSavedDeals setIsDelete={setIsDelete}    /> */}
                {isDeletePopup == true ? (
                  <DeleteSavedDeals
                    setIsDelete={setIsDelete}
                    setIsDeletePopup={setIsDeletePopup}
                    message={"product"}
                  />
                ) : (
                  ""
                )}
              </>
            </section>
          )}
        </>
      )}
      {error && <p> </p>}
    </>
  );
};
const AboutStore = ({ titlePayload, domain }) => {
  const data = useSelector((state) => state.productList);
  const loading = useSelector((state) => state.loadingProductList);
  const error = useSelector((state) => state.errorProductList);
  const [showText, setShowText] = useState(false);
  const aboutStoreText = `
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni perferendis, consequatur delectus nesciunt quisquam labore.
                 Et velit dignissimos magni numquam consequuntur dolorum alias assumenda saepe,
                 iusto error molestias nisi accusamus.
                 Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora expedita, dolor amet alias cupiditate nisi, 
                 vitae sint laboriosam corporis iusto voluptatum assumenda in aliquid autem commodi nemo perferendis? Alias, ut?`;

  return (
    <>
      {loading ? (
        <div style={{ margin: "5px" }}>
          {Array.from({ length: 1 }, (_, index) => (
            <div
              className="card"
              style={{
                width: "100%",
                padding: "0px 0px 20px 20px",
                background: "none",
                boxShadow: "none",
              }}
            >
              <div>
                <Skeleton
                  height={10}
                  width={150}
                  style={{ marginTop: "40px" }}
                />
                <Skeleton height={10} width={150} />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <>
          {data.about_store != undefined && data.about_store != "" ? (
            <section
              id="about_store"
              // style={{ marginBottom: "20px" }}
            >
              <h3 style={{ margin: "0" }}>About Store</h3>
              <p
                className="initial_about_store"
                style={{ display: !showText ? "block" : "none" }}
              >
                {data.about_store.substring(0, 100)}
                {/* {aboutStoreText.substring(0,100)} */}
                <span
                  onClick={() => {
                    setShowText(true);
                  }}
                >
                  read more...
                </span>
              </p>

              <p
                className="expandText"
                style={{ display: showText ? "block" : "none" }}
              >
                {data.about_store}
                {/* {aboutStoreText} */}
                <span
                  onClick={() => {
                    setShowText(false);
                  }}
                >
                  read less...
                </span>
              </p>
            </section>
          ) : (
            ""
          )}
        </>
      )}
      {error && <p> </p>}
    </>
  );
};
const ShopAt = ({ titlePayload, domain }) => {
  const data = useSelector((state) => state.productList);
  const loading = useSelector((state) => state.loadingProductList);
  const error = useSelector((state) => state.errorProductList);

  const { uniqueIdData } = useUniqueId();
  const [uuid, setUuid] = useState("");
  // const platform =getStoreName(domain)

  useEffect(() => {
    if (uniqueIdData && uniqueIdData.user_id) {
      console.log("uniqueIdData ", uniqueIdData.user_id);
      setUuid(uniqueIdData.user_id);
    }
  }, [uniqueIdData]);

  const getDealsUrl = (url, storeId) => {
    // const dealUrl = buildUrl(uuid, url);
    //  console.log('dealUrl ',dealUrl)
    handleRedirect(url, storeId, true, domain);
  };

  return (
    <>
      {loading ? (
        <div
          className="card"
          style={{ display: "flex", background: "white", margin: "5px" }}
        >
          {Array.from({ length: 10 }, (_, index) => (
            <div className="shopAt" style={{ width: "auto" }}>
              <Skeleton
                height={90}
                width={90}
                src={watchImg}
                alt=""
                className="images_size"
              />
            </div>
          ))}
        </div>
      ) : (
        <>
          {data.shop_at !== undefined && data.shop_at.length > 0 && (
            <section id="shopAt">
              <Slider
                top={"-7%"}
                right={"1px"}
                scrollPercent={95}
                lastItemView={1.0}
                items={data.shop_at}
                title="People also shop at"
                renderItem={(shop, index) => (
                  <li
                    key={index}
                    onClick={() => getDealsUrl(shop.store_url, shop.store_id)}
                    className="storeList"
                  >
                    <img
                      src={data.store_img_base_url + shop.store_logo}
                      alt="Store logo"
                      className="icon"
                    />
                  </li>
                )}
                height="auto"
              />
            </section>
          )}
        </>
      )}
      {error && <p> </p>}
    </>
  );
};

const ShippingInfo = ({ titlePayload, domain }) => {
  const data = useSelector((state) => state.productList);
  const loading = useSelector((state) => state.loadingProductList);
  const error = useSelector((state) => state.errorProductList);
  console.log("archana", domain);
  return (
    <>
      {loading ? (
        <div
          className="card"
          style={{
            display: "flex",
            background: "none",
            margin: "5px",
            boxShadow: "none",
          }}
        >
          {Array.from({ length: 3 }, (_, index) => (
            <div className="shipping_info">
              <Skeleton
                height={90}
                width={90}
                src={watchImg}
                alt=""
                className="images_size"
              />
            </div>
          ))}
        </div>
      ) : (
        <>
          {(data.shipping_info &&
            data.shipping_info.shipping_info != undefined) ||
          data.shipping_info.delivery_info != undefined ||
          data.shipping_info.return_info != undefined ? (
            <section id="shipping_section">
              <h3>Shipping Info</h3>
              <ul className="shipping_info" style={{ padding: "0 14px 0px" }}>
                <li
                  disabled={
                    data.shipping_info.shipping_info == undefined ? true : false
                  }
                >
                  <i
                    class="fa fa-shopping-bag icon"
                    aria-hidden="true"
                    style={{
                      color: " #caabab",
                      fontSize: "18px",
                      margin: "5px 100px -3px 0px",
                    }}
                  ></i>

                  {data.shipping_info.shipping_info != null ? (
                    <span>{data.shipping_info.shipping_info}</span>
                  ) : (
                    <span style={{ color: "#9e9a9a" }}>
                      Free shipping
                      <br />
                      Min. order may apply
                    </span>
                  )}
                </li>
                <li
                  disabled={
                    data.shipping_info.delivery_info == undefined ? true : false
                  }
                >
                  <i
                    class="fa fa-shipping-fast icon"
                    style={{
                      color: " #caabab",
                      fontSize: "18px",
                      margin: "5px 100px -3px 0px",
                    }}
                  ></i>

                  {data.shipping_info.delivery_info != null ? (
                    <span>{data.shipping_info.delivery_info}</span>
                  ) : (
                    <span style={{ color: "#9e9a9a" }}>
                      Delivery info <br />
                      is not available
                    </span>
                  )}
                </li>
                <li
                  disabled={
                    data.shipping_info.return_info == undefined ? true : false
                  }
                >
                  <i
                    class="fa fa-arrow-left icon"
                    aria-hidden="true"
                    style={{
                      color: " #caabab",
                      fontSize: "18px",
                      margin: "5px 100px -3px 0px",
                    }}
                  ></i>

                  {data.shipping_info.return_info != null ? (
                    <span>{data.shipping_info.return_info}</span>
                  ) : (
                    <span style={{ color: "#9e9a9a" }}>
                      Returns info <br />
                      is not available
                    </span>
                  )}
                </li>
              </ul>
            </section>
          ) : (
            ""
          )}
        </>
      )}
      {error && <p> </p>}
    </>
  );
};

const ProductDetails = ({ domain, activeProductData }) => {
  const [recentData, setRecentData] = useState(activeProductData);
  console.log("ProductDetails", recentData);

  return (
    <>
      {activeProductData && ( // Check if activeProductData exists
        <section id="shipping_section">
          <h3>Product Details</h3>
          <div style={{ padding: "0px 14px 10px" }}>
            <SingleProductDisplay singleProduct={activeProductData} />
          </div>
        </section>
      )}
    </>
  );
};

export {
  HotDeals,
  SimilarDeals,
  RecentlyViewdDeals,
  TrendingDeals,
  AboutStore,
  ShopAt,
  ShippingInfo,
  ProductDetails,
};
