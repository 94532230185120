import React, { useEffect, useState } from "react";
import SavedComponent from "./savedComponent";
import useGetWishList from "./getWishListHook";

const RecentlyViewedComponent = ({ domain }) => {
  //   const [recentData, setRecentData] = useState([
  //     {
  //         "id": 209,
  //         "user_id": 2419,
  //         "uuid_type": "deal",
  //         "uuid": "",
  //         "product_url": "https://www.ajio.com/allen-solly-boys-zip-front-hooded-quilted-jacket/p/469580018_blue",
  //         "product_title": "Boys Zip-Front Hooded Quilted Jacket",
  //         "product_category": "",
  //         "product_image": "https://assets.ajio.com/medias/sys_master/root/20231214/3QM0/657b38e3ddf7791519d04ad1/-473Wx593H-469580018-blue-MODEL.jpg",
  //         "product_price": "1624",
  //         "is_purchased": 0,
  //         "wishlist_status": 1,
  //         "list_id": "2,1,4",
  //         "created_at": "2024-07-15 20:37:09",
  //         "is_favourite": 1,
  //         "is_fulfilled": 1,
  //         "is_mute_notification": 1,
  //         "notification_option_id": "16",
  //         "is_review": 1
  //     },
  //     {
  //         "id": 204,
  //         "user_id": 2419,
  //         "uuid_type": "deal",
  //         "uuid": "",
  //         "product_url": "https://in.puma.com/in/en/pd/scuderia-ferrari-miami-mens-tee/630744?swatch=02",
  //         "product_title": "Scuderia Ferrari Miami Men's Tee",
  //         "product_category": "",
  //         "product_image": "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/630744/02/mod01/fnd/IND/fmt/png/Scuderia-Ferrari-Miami-Men",
  //         "product_price": "3199",
  //         "is_purchased": 0,
  //         "wishlist_status": 1,
  //         "list_id": "",
  //         "created_at": "2024-07-12 21:29:24",
  //         "is_favourite": 0,
  //         "is_fulfilled": 0,
  //         "is_mute_notification": 1,
  //         "notification_option_id": 1,
  //         "is_review": 1
  //     },
  //     {
  //         "id": 203,
  //         "user_id": 2419,
  //         "uuid_type": "deal",
  //         "uuid": "z5Sc8l0xhEl3",
  //         "product_url": "https://www.flipkart.com/rasna-native-haat-shikanji/p/itmf4sjjgbq8qgrg?pid=CNCF4SJJFGGHAGGK&lid=LSTCNCF4SJJFGGHAGGKRDIUXY",
  //         "product_title": "Flipkart Grocery - Products up to 91% Off (Pune location)",
  //         "product_category": "",
  //         "product_image": "https://lannister.deals2buy.in/assets/images/IMG_Flipkart_Grocery_Products_Pune_20240709_11_13_10_flipkart.png",
  //         "product_price": "9",
  //         "is_purchased": 0,
  //         "wishlist_status": 1,
  //         "list_id": "4",
  //         "created_at": "2024-07-12 20:21:05",
  //         "is_favourite": 0,
  //         "is_fulfilled": 0,
  //         "is_mute_notification": 1,
  //         "notification_option_id": "16",
  //         "is_review": 1
  //     },
  //     {
  //         "id": 202,
  //         "user_id": 2419,
  //         "uuid_type": "deal",
  //         "uuid": "iu8PvYwA4YXa",
  //         "product_url": "https://www.amazon.in/dp/B0C1P954NL?tag=deals2buy0b-21",
  //         "product_title": "Fastrack Fpods(New Launch) FX100 Bluetooth TWS In-Ear Earbuds with 40 Hrs Playtime|BT V5.3|13mm Extra Deep Bass Drivers|Quad Mic ENC for Clear Calls|Ultra Low 50ms Latency Gaming Mode|NitroFast Charge",
  //         "product_category": "",
  //         "product_image": "https://lannister.deals2buy.in/assets/images/IMG_Fastrack_Fpods_New_Launch_20240711_20_32_28_amazon.png",
  //         "product_price": "799",
  //         "is_purchased": 0,
  //         "wishlist_status": 1,
  //         "list_id": "4,1",
  //         "created_at": "2024-07-12 20:20:54",
  //         "is_favourite": 1,
  //         "is_fulfilled": 0,
  //         "is_mute_notification": 1,
  //         "notification_option_id": 1,
  //         "is_review": 1
  //     },
  //     {
  //         "id": 201,
  //         "user_id": 2419,
  //         "uuid_type": "deal",
  //         "uuid": "uvJX84OQdorc",
  //         "product_url": "https://www.amazon.in/dp/B0CYT5VCZ8?tag=deals2buy0b-21",
  //         "product_title": "Portronics Vader Pro Wireless Gaming Mouse with 2.4 GHz Receiver, 6 Buttons, Thumb Support, High-Precision Tracking, Ergonomic Comfort, Adjustable Optical DPI for Laptop, PC, Mac (Indigo Blue)",
  //         "product_category": "",
  //         "product_image": "https://lannister.deals2buy.in/assets/images/IMG_Portronics_Vader_Pro_Wireless_20240712_17_01_19_amazon.png",
  //         "product_price": "399",
  //         "is_purchased": 0,
  //         "wishlist_status": 1,
  //         "list_id": "4,1",
  //         "created_at": "2024-07-12 20:20:52",
  //         "is_favourite": 1,
  //         "is_fulfilled": 0,
  //         "is_mute_notification": 1,
  //         "notification_option_id": 1,
  //         "is_review": 1
  //     },
  //     {
  //         "id": 200,
  //         "user_id": 2419,
  //         "uuid_type": "deal",
  //         "uuid": "",
  //         "product_url": "https://www.flipkart.com/flipkart/p/item?pid=NDFGRK5DRJUZ38ZA&cmpid=product.share.pp&_refId=PP.03952d7c-6620-432b-9265-b90a9e01c7b5.NDFGRK5DRJUZ38ZA&_appId=WA&click_id=1720791558",
  //         "product_title": "OPEN SECRET Premium /Kaju|100% Natural|Tasty, Crunchy| Immunity Boosting Nuts| Cashews  (1 kg)",
  //         "product_category": "",
  //         "product_image": "https://rukminim2.flixcart.com/image/416/416/xif0q/nut-dry-fruit/w/w/s/1-premium-cashews-kaju-100-natural-tasty-crunchy-immunity-original-imagrk5dua5g2e6x.jpeg?q=70&crop=false",
  //         "product_price": "863",
  //         "is_purchased": 0,
  //         "wishlist_status": 1,
  //         "list_id": "",
  //         "created_at": "2024-07-12 20:20:32",
  //         "is_favourite": 0,
  //         "is_fulfilled": 0,
  //         "is_mute_notification": 1,
  //         "notification_option_id": 1,
  //         "is_review": 1
  //     },
  //     {
  //         "id": 199,
  //         "user_id": 2419,
  //         "uuid_type": "deal",
  //         "uuid": "YUN60iwtM3H9",
  //         "product_url": "https://www.amazon.in/dp/B0D3XC2HMN?tag=deals2buy0b-21",
  //         "product_title": "TOSHIBA 164 cm (65 inches) C450NP Series 4K Ultra HD Smart QLED TV (Black)",
  //         "product_category": "",
  //         "product_image": "https://lannister.deals2buy.in/assets/images/IMG_TOSHIBA_cm_inches_Series_20240711_22_06_03_amazon.png",
  //         "product_price": "54990",
  //         "is_purchased": 0,
  //         "wishlist_status": 1,
  //         "list_id": "4,3,2",
  //         "created_at": "2024-07-12 17:39:22",
  //         "is_favourite": 0,
  //         "is_fulfilled": 1,
  //         "is_mute_notification": 1,
  //         "notification_option_id": "17",
  //         "is_review": 1
  //     }
  // ]);

  const [recentData, setRecentData] = useState([]);
  const [isProcessed, setIsProcessed] = useState(false);
  const { wishListData, loadingWishList, handleGetToWishList, wishListError } =
    useGetWishList();
  useEffect(() => {
    // if (CouponData && domain) {
    // Add event listener to receive messages from the plugin
    const parentWindow = window.parent;
    console.log(domain);

    const startTime = performance.now();
    console.log("startTime ", startTime);
    console.log("domain33", domain);
    parentWindow.postMessage(
      { action: "recentUserHistory", startTime },
      domain
    );
  }, [domain]);
  window.addEventListener("message", (event) => {
    const receivedData = event.data;

    console.log("receivedData888", receivedData);
    if (receivedData.action == "recentHistoryData") {
      const endTime = performance.now();
      const totalElapsedTime = (endTime - event.data.startTime) / 1000;
      const steps = event.data.steps;

      // Calculate the total time from all steps
      const totalTime = Object.values(steps).reduce(
        (acc, time) => acc + parseFloat(time),
        0
      );

      console.log(
        `Total time taken for round trip: ${totalTime.toFixed(2)} seconds`
      );
      console.log(`Steps timings:`, steps);

      const uniqueTitles = Array.from(
        new Set(receivedData.data.map((item) => item.product_title))
      );
      console.log("uniqueTitles", uniqueTitles);
      // Map unique URLs to their respective objects
      const uniqueProducts = uniqueTitles.map((title) =>
        receivedData.data.find((item) => item.product_title === title)
      );

      console.log("uniqueProducts ", uniqueProducts);
      const updatedArray = uniqueProducts.map((obj) => {
        const newObj = {};
        newObj.category = obj.breadcrumb;
        newObj.product_price = obj.price;
        newObj.product_title = obj.product_title;
        newObj.product_url = obj.product_url;
        newObj.product_image = obj.imgSrc;
        newObj.created_at = obj.created_at;

        return newObj;
      });
      updateRecentDataFunction(updatedArray);
      //setRecentData(receivedData.data);
    }
  });
  const updateRecentDataFunction = (filteredProducts) => {
    console.log("isProcessed ", isProcessed);
    if (!isProcessed && filteredProducts.length > 0) {
      let updatedRecentData;

      console.log("filteredProducts ", filteredProducts);
      if (wishListData?.data?.length > 0) {
        updatedRecentData = filteredProducts.map((recent) => {
          const savedMatch = wishListData.data.find(
            (saved) => saved.product_url === recent.product_url
          );
          return savedMatch ? { ...recent, ...savedMatch } : recent;
        });
      } else {
        updatedRecentData = filteredProducts;
      }

      console.log("updatedRecentData", updatedRecentData);
      setRecentData(updatedRecentData);
      setIsProcessed(true);
      return updatedRecentData;
    }
  };

  return (
    <>
      <SavedComponent
        savedProductData={recentData}
        domain={domain}
        filterOption="Last Viewed"
        listAndFilter="Filter"
        filterByLists={false}
        Heading="Recently Viewed"
      />
    </>
  );
};

export default RecentlyViewedComponent;
